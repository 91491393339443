import styles from './userButton.module.css'
import { BiMenu } from "react-icons/bi";
import axios from 'axios'
import {useDispatch, useSelector} from 'react-redux'
import { authActions } from '../../store/authSlice';
import {useState} from 'react'
import { Link } from 'react-router-dom';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

function UserButton() {
    const dispatch=useDispatch()
    const [clicker,setClicker] = useState(0)
    

    const [display, setDisplay] = useState('default');
    const [align, setAlign] = useState('center');
    const [position, setPosition] = useState('anchor');
    const [viewScroll, setViewScroll] = useState('auto');

    const isAdmin = useSelector(state=>state.auth.isAdmin)
    
    
    const direction="bottom"
    
    const style = {color:"white", fontSize:"35px"}
    

    function logoutChandler(){
       
        dispatch(authActions.setEmail(null))
        dispatch(authActions.setAdmin(null))
        dispatch(authActions.setAccess(null))
        dispatch(authActions.setReload(null))
        dispatch(authActions.setLoged(false))
        localStorage.removeItem('authTokens')
    }
    return (
        <div className={styles.block} >

       {isAdmin==false && <Menu  menuButton={<MenuButton className={styles.but}><BiMenu className={styles.icon}/></MenuButton>}
        key={direction} direction={direction}
        align={align} position={position} viewScroll={viewScroll}
        arrow={display === 'arrow'}
        offsetX={display === 'offset' &&
            (direction === 'left' || direction === 'right')
            ? 12 : 0}
        offsetY={display === 'offset' &&
            (direction === 'top' || direction === 'bottom')
            ? 12 : 0}>
        <Link className={styles.link}  to="/userInfo"><MenuItem> <div >User Information</div></MenuItem></Link>
        <Link className={styles.link}  to="/patch_notes"><MenuItem> <div >Patch Notes</div></MenuItem></Link>
        <Link className={styles.link}  to="/recomend_nams"><MenuItem> <div >Recommend new NAM</div></MenuItem></Link>
        <Link className={`${styles.link} ${styles.extraLink}`}  to="/about"><MenuItem> <div >About Us</div></MenuItem></Link>
        <div className={styles.horizontal}></div>
        <MenuItem onClick={logoutChandler}>Logout</MenuItem>
        
        </Menu>
        }

    {isAdmin==true && <Menu menuButton={<MenuButton className={styles.but}><BiMenu className={styles.icon}/></MenuButton>}
        key={direction} direction={direction}
        align={align} position={position} viewScroll={viewScroll}
        arrow={display === 'arrow'}
        offsetX={display === 'offset' &&
            (direction === 'left' || direction === 'right')
            ? 12 : 0}
        offsetY={display === 'offset' &&
            (direction === 'top' || direction === 'bottom')
            ? 12 : 0}>

       
        <Link className={styles.link} to="/add" ><MenuItem className={styles.manage}> <div >Manage Database</div></MenuItem></Link>
                
        <Link className={styles.link}  to="/editNews"> <MenuItem className={styles.manage}><div >Manage News</div></MenuItem></Link>
        <Link className={styles.link}  to="/userInfo"><MenuItem> <div >User Information</div></MenuItem></Link>
        <Link className={styles.link}  to="/accounts"><MenuItem> <div >Accounts</div></MenuItem></Link>
        <Link className={styles.link}  to="/patch_notes"><MenuItem> <div >Patch Notes</div></MenuItem></Link>
        <Link className={styles.link}  to="/recomend_nams"><MenuItem> <div >Recommend new NAM</div></MenuItem></Link>
        <Link className={`${styles.link} ${styles.extraLink}`}  to="/about"><MenuItem> <div >About Us</div></MenuItem></Link>
        <div className={styles.horizontal}></div>
        <MenuItem onClick={logoutChandler}>Logout</MenuItem>
        </Menu>
        }


        {isAdmin==null && <Menu  menuButton={<MenuButton className={styles.but}><BiMenu style={style}/></MenuButton>}
          key={direction} direction={direction}
          align={align} position={position} viewScroll={viewScroll}
          arrow={display === 'arrow'}
          offsetX={display === 'offset' &&
              (direction === 'left' || direction === 'right')
              ? 12 : 0}
          offsetY={display === 'offset' &&
              (direction === 'top' || direction === 'bottom')
              ? 12 : 0}>
          <Link className={`${styles.link} ${styles.extraLink}`}  to="/about"><MenuItem> <div >About Us</div></MenuItem></Link>
          <MenuItem onClick={e=>{window.location.href="/login"}}>Login</MenuItem>
          
          </Menu>
          }
        </div>

        
            );
          }


    
  
  export default UserButton;