import styles from './ProviderList.module.css'
import Filter from './Filter'
import ProviderItem from './ProviderItem'
import {useState,useEffect} from 'react'
import { useSelector } from "react-redux";
import LabMenu from '../../../explore/details/labs/labMenu';
import  Select  from "react-select";
import blob3 from '../../../landing/blob3.png'
import axios from 'axios'
function ProviderList(){
    const authToken = useSelector((state) => state.auth.access);
    const [name,setName]=useState([])
    const [nameOptions,setNameOptions]=useState([])
    const [country,setCountry]=useState([])
    const [countryOptions,setCountryOptions]=useState([])
    const [itemList,setItemList]=useState([])
    const [page,setPage]=useState(1)
    const [next,setNext]=useState('')
    const [prev,setPrev]=useState('')
    const [maxList,setMaxList]=useState(1)
    const [pages,setPages] = useState(Array.from({length: maxList}, (_, i) => i + 1))
    const pages_list =pages.map((x) => ({value:x,label:x}))
    const [pageSelected,setPageSelected] = useState({value:1,label:1})

    const colorStyle= {
    
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px',
          }),
      
        control: (styles) => ({ ...styles, backgroundColor: "rgb(238,238,238)",
        minHeight: '30px',
        height: '30px',}),
        valueContainer: (provided, state) => ({
            ...provided,
            textOverflow: "ellipsis",
            width:"150px",
            maxWidth: "150px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            display: "flex",
            flexDirection:"row",
            height:"30px",
            maxHeight: "30px"
          })
    }

    function pageListChandler(e){
        setPage(e.value)
    }
    function prevChandler(event){
        if (page!=pages[0]){
         setPage(parseInt(page)-1)
        }
        else{
         return
        }
 
     }
     function nextChandler(event){
        if (page!=pages[pages.length-1]){
         setPage(parseInt(page)+1)
        }
        else{
         return
        }
 
     }
    async function getCountries(){
        const request= await axios.get(`${process.env.REACT_APP_DOMAIN}/api/service_provider_country?name=${name}`,{
            headers:{
                "Content-Type": "application/json",
                // Authorization: "Bearer " + String(authToken),
            }
        },
        )
        .then(function(response){
            setCountryOptions(response.data.map((x)=>({name:x,value:x})))
        })
        .catch(function(error){

        })
        .finally(function(){

        })
    }
    async function getNames(){
        const request= await axios.get(`${process.env.REACT_APP_DOMAIN}/api/service_provider_names?country=${country}`,{
            headers:{
                "Content-Type": "application/json",
                // Authorization: "Bearer " + String(authToken),
            }
        },
        )
        .then(function(response){
            setNameOptions(response.data.map((x)=>(

                {name:x.name,value:x.name}
            )
            ))
        })
        .catch(function(error){

        })
        .finally(function(){

        })
    }
    async function getProviders(){
        const request= await axios.get(`${process.env.REACT_APP_DOMAIN}/api/service_provider_list?country=${country}&&name=${name}&&page=${page}`,{
            headers:{
                "Content-Type": "application/json",
                // Authorization: "Bearer " + String(authToken),
            }
        },
        )
        .then(function(response){
           
           setItemList(response.data.results.map((x,i)=>{
            x.key=i+1
            return x
            }))
       
           setNext(response.data.next)
           setPrev(response.data.prev)
           setPages(Array.from({length:Math.ceil(response.data.count/5) }, (_, i) => i + 1))
        })
        .catch(function(error){

        })
        .finally(function(){

        })
    }
    useEffect(()=>{
        getNames()
        getProviders()
    },[country])
    useEffect(()=>{
        getCountries()
        getProviders()
    },[name])
    useEffect(()=>{
        getProviders()
        setPageSelected({value:page,label:page})
        
    },[page])
    useEffect(()=>{
        getCountries()
        getNames()
        getProviders()
    },[])
    return(
        <div className={styles.block}>
            <img src={blob3} className={styles.tlo}/>
            <Filter name={name} setName={setName} nameOptions={nameOptions} country={country} setCountry={setCountry} countryOptions={countryOptions}/>
            <LabMenu providers={itemList} title={"NAMs Providers List"}/>
            <div className={styles.table_elements}>
                <div className={styles.page_of_pages}>Page {page} of {pages[pages.length-1]}</div>
                <div className={styles.listWBut}>
                    <div className={styles.pager}>
                        <Select
                           options={pages_list}
                           value={pageSelected}
                            styles={colorStyle}
                            onChange={pageListChandler}
                             allowSelectAll={false}
                             closeMenuOnSelect={true}
                             hideSelectedOptions={false}
                             menuPlacement="top"
                        />
                    </div>
                    <div className={styles.table_but}>
                    {page!=1 ?
                        <button onClick={prevChandler} className={page!=1 ? styles.next_but : styles.next_but_dis}>Previous</button>:
                        <button  className={page!=1 ? styles.next_but : styles.next_but_dis}>Previous</button>}
                        <button onClick={nextChandler} className={page!=pages[pages.length-1] ? styles.next_but : styles.next_but_dis}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ProviderList